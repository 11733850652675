/**
 * Instance of Metis (singleton)
 * @var metis
 * @type {Metis}
 */
var metis = (/**
 * @param {Window} window
 * @param {HTMLDocument} document
 * @param {jQuery} $
 */
    function (window, document, $) {
        'use strict';


        /**
         * @file This file contains the Metis Object/Class
         * @copyright 2016, Memorial University of Newfoundland
         * @author Dale Conway <conway@mun.ca>
         */

        /**
         * FileInfo coming from blade
         * @typedef {Object} Metis~FileParameters
         * @property {String} filename
         * @property {String} subRoute
         * @property {String} displayName
         */

        //private pseudo-members
        var _slug, _itemId, _self, _uploader, _allowSubmit = false,
            // 3=Kb, 6=Mb, 9=Gb
            _sizeLimit = 151 * Math.pow(10, 6);

        var _area = 'collection/';

        //set config for each file status table
        var fileTypes = {
            edit: {
                'N': {
                    'idPostfix': 'drafts',
                    'subRoute': 'drafts/',
                    'buttons': [
                        ['file-view btn btn-info btn-sm', '<span class="glyphicon glyphicon-open"></span> Open'],
                        ['file-publish btn btn-primary btn-sm', '<span class="glyphicon glyphicon-ok"></span> Publish'],
                        ['file-delete btn btn-danger btn-sm', '<span class="glyphicon glyphicon-trash"></span> Delete']
                    ]
                },
                'P': {
                    'idPostfix': 'production',
                    'subRoute': '',
                    'buttons': [
                        ['file-view btn btn-info btn-sm', '<span class="glyphicon glyphicon-open"></span> Open'],
                        ['file-trash btn btn-danger btn-sm', '<span class="glyphicon glyphicon-trash"></span> Trash']
                    ]
                },
                'D': {
                    'idPostfix': 'deleted',
                    'subRoute': 'deleted/',
                    'buttons': [
                        ['file-view btn btn-info btn-sm', '<span class="glyphicon glyphicon-open"></span> Open'],
                        ['file-restore btn btn-primary btn-sm', '<span class="glyphicon glyphicon-repeat"></span> Restore'],
                        ['file-delete btn btn-danger btn-sm', '<span class="glyphicon glyphicon-trash"></span> Delete']
                    ]
                }
            },
            view: {
                'P': {
                    'idPostfix': 'production',
                    'subRoute': '',
                    'buttons': [
                        ['file-view btn btn-info btn-sm', '<span class="glyphicon glyphicon-open"></span> Open']
                    ]
                }
            }
        };

        /**
         *
         * Create a new instance.
         *
         * Note: an instance is created when this is file loaded and added to an external namespace or object*
         *
         * @class metis
         * @classdesc Contains basic functions for the Metis application
         * @copyright 2016, Memorial University of Newfoundland
         * @author Dale Conway <conway@mun.ca>
         */
        function Metis() {
            _self = this;
        }

        /**
         * Set the collection slug, used to determine routes
         * @param slug
         */
        Metis.prototype.setSlug = function (slug) {
            _slug = slug;
        };

        Metis.prototype.setArea = function (area) {
            _area = area;
        };


        /**
         * Set the itemId, used to determine routes
         * @param itemId
         */
        Metis.prototype.setItemId = function (itemId) {
            _itemId = itemId;
        };

        /**
         * Init editors
         */
        Metis.prototype.startFroalaEditors = function () {
            $('.editor-full').froalaEditor({
                height: 300
            });
            $('.editor-simple').froalaEditor({
                toolbarButtons: ['bold', 'italic', 'underline', 'html', 'undo', 'redo', '|'],
                height: 300
            });
        };

        /**
         * Get the file route
         * @param filename {string|boolean}
         * @returns {string}
         */
        Metis.prototype.getFileRoute = function (filename) {
            filename = filename !== false ? '/' + filename : '';
            return _area + _slug + '/item/' + _itemId + '/files' + filename;
        };

        /**
         * Refresh the file table list
         */
        Metis.prototype.updateFileListTable = function (action) {
            var path = _self.getFileRoute(false);
            var actionFileTypes = fileTypes[action];
            $.ajax({
                url: path
            })
                .done(function (fileList) {
                    //for each type (production, draft, etc..) parse the data and setup the table)
                    for (var fileType in actionFileTypes) {
                        var files, fileTypeConfig, numberOfFiles, fileListContainer, tableBody;

                        files = fileList[fileType];
                        fileTypeConfig = actionFileTypes[fileType];
                        numberOfFiles = files.length;
                        fileListContainer = $('#file-list-container-' + fileTypeConfig['idPostfix']); //e.g. #file-list-container-production
                        tableBody = fileListContainer.find('tbody');
                        if (tableBody.length) {
                            if (numberOfFiles > 0) {
                                fileListContainer.removeClass('hidden');
                            } else {
                                fileListContainer.addClass('hidden');
                            }

                            tableBody.html('');

                            files.forEach(function (filename) {
                                //var filename = files[fileIndex];

                                var tableRow = $('<tr>').appendTo(tableBody),
                                    timestamp = filename.substr('-', filename.search('-')),
                                    displayName = filename.substr(filename.search('-') + 1),


                                    filename = filename.replace('-', '/');
                                timestamp = new Date(parseInt(timestamp) * 1000);

                                $('<td>').appendTo(tableRow).html(displayName);
                                $('<td>').appendTo(tableRow).html(timestamp.toDateString());
                                // add some buttons/columns to the table;
                                for (var buttonIndex = 0; buttonIndex < fileTypeConfig['buttons'].length; buttonIndex++) {
                                    var buttonConfig = fileTypeConfig['buttons'][buttonIndex];
                                    $('<a>').appendTo($('<td>').appendTo(tableRow))
                                        .data('filename', filename)
                                        .data('displayName', displayName)
                                        .data('subRoute', fileTypeConfig['subRoute'])
                                        .addClass(buttonConfig[0])
                                        .html(buttonConfig[1]);

                                }
                            });
                        }
                    }
                });
        };

        /**
         * Register Event Handlers for the Item From
         */
        Metis.prototype.registerEventHandlersForItemForm = function () {

            $('.file-list')
                .on('click', '.file-view', function () {
                    /** @type {Metis~FileParameters} */
                    var fileParameters = $(this).data();
                    $(this).attr('href', _self.getFileRoute(fileParameters.subRoute + fileParameters.filename));
                }
                )
                .on('click', '.file-delete', function (e) {
                    e.preventDefault();
                    /** @type {Metis~FileParameters} */
                    var fileParameters = $(this).data();
                    bootbox.confirm({
                        title: "Delete File?",
                        message: '<b>' + fileParameters.displayName + '</b>',
                        callback: function (result) {
                            if (result) {
                                $.ajax({
                                    url: _self.getFileRoute(fileParameters.subRoute + fileParameters.filename),
                                    method: 'post',
                                    data: { _method: 'delete', _token: window.APP_CSRF_TOKEN }
                                })
                                    .done(function () {
                                        toastr.success('File deleted');
                                        metis.updateFileListTable('edit');
                                    });
                            }
                        }
                    });
                })
                .on('click', '.file-trash', function (e) {
                    e.preventDefault();
                    /** @type {Metis~FileParameters} */
                    var fileParameters = $(this).data();
                    bootbox.confirm({
                        title: "Trash File?",
                        message: '<b>' + fileParameters.displayName + '</b>',
                        callback: function (result) {
                            if (result) {
                                $.ajax({
                                    url: _self.getFileRoute(fileParameters.subRoute + fileParameters.filename),
                                    method: 'post',
                                    data: { _method: 'delete', _token: window.APP_CSRF_TOKEN }
                                })
                                    .done(function () {
                                        toastr.success('File trashed');
                                        metis.updateFileListTable('edit');
                                    });
                            }
                        }
                    })
                })
                .on('click', '.file-restore', function (e) {
                    e.preventDefault();
                    /** @type {Metis~FileParameters} */
                    var fileParameters = $(this).data();
                    bootbox.confirm({
                        title: "Restore File?",
                        message: '<b>' + fileParameters.displayName + '</b>',
                        callback: function (result) {
                            if (result) {
                                $.ajax({
                                    url: _self.getFileRoute(fileParameters.subRoute + fileParameters.filename),
                                    method: 'post',
                                    data: { _method: 'put', _token: window.APP_CSRF_TOKEN }
                                })
                                    .done(function () {
                                        toastr.success('File restored');
                                        metis.updateFileListTable('edit');
                                    });
                            }
                        }
                    });
                })
                .on('click', '.file-publish', function (e) {
                    e.preventDefault();
                    /** @type {Metis~FileParameters} */
                    var fileParameters = $(this).data();
                    bootbox.confirm({
                        title: "Publish File?",
                        message: '<b>' + fileParameters.displayName + '</b>',
                        callback: function (result) {
                            if (result) {
                                $.ajax({
                                    url: _self.getFileRoute(fileParameters.subRoute + fileParameters.filename),
                                    method: 'post',
                                    data: { _method: 'put', _token: window.APP_CSRF_TOKEN }
                                })
                                    .done(function () {
                                        toastr.success('File published');
                                        metis.updateFileListTable('edit');
                                    });
                            }
                        }
                    });
                });


            setInterval(function () {
                var status = $('#status').val();
                if (status == 'inactive') {

                    $.ajax({
                        method: 'post',
                        url: '',
                        data: $('#update-item-form').serializeArray()
                    })
                        .done(function () {
                            toastr.success('', 'Saved', {
                                "positionClass": "toast-bottom-right",
                                "preventDuplicates": true,
                                "onclick": null,
                                "showDuration": "500",
                                "hideDuration": "500",
                                "timeOut": "1000",
                                "showEasing": "linear",
                                "hideEasing": "linear",
                                "showMethod": "fadeIn",
                                "hideMethod": "fadeOut"
                            });
                        })
                        .fail(function () {
                            toastr.error('Document was not saved.', 'Autosave Error', {
                                "preventDuplicates": true,
                                "onclick": null,
                                "showDuration": "300",
                                "hideDuration": "1000",
                                "timeOut": "5000",
                                "showEasing": "linear",
                                "hideEasing": "linear",
                                "showMethod": "fadeIn",
                                "hideMethod": "fadeOut"
                            })
                        })

                }
            }, 15000);

            $('#linkVideoModal').on('click', '.btn-save-link', function (e) {
                $.post(_area + _slug + '/item/' + _itemId + '/link', $('#linkVideoForm').serialize())
                    .done(function (results) {
                        //add the html from the results to the list of videos.
                        $('#linked-videos').append(results.videoCard);
                        $('#linkVideoForm')[0].reset();
                    })
                    .fail(function () {
                        toastr.error('Video not linked.', 'Linking Error', {
                            "preventDuplicates": true,
                            "onclick": null,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "timeOut": "5000",
                            "showEasing": "linear",
                            "hideEasing": "linear",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut"
                        })
                    });
            });


            $('#linked-videos').on('click', '.btn-unlink', function (e) {
                var attachmentId = $(this).data('id');
                $.ajax({
                    url: _area + _slug + '/item/' + _itemId + '/unlink/' + attachmentId,
                    method: 'post',
                    data: { _token: window.APP_CSRF_TOKEN }
                })
                    .done(function (results) {
                        //add the html from the results to the list of videos.
                        $('#video-card-' + attachmentId).remove();
                    })
                    .fail(function () {
                        toastr.error('Video not un-linked.', 'Un-Linking Error', {
                            "preventDuplicates": true,
                            "onclick": null,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "timeOut": "5000",
                            "showEasing": "linear",
                            "hideEasing": "linear",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut"
                        })
                    });
            });

            $('#update-item-form').on('click', '.btn-submit', function (e) {
                e.preventDefault();
                var status = $(this).data('status');
                $('#status').val(status);
                _allowSubmit = true;
                var numberOfUploads = _uploader.getUploads({ status: 'uploading' }).length;
                if (numberOfUploads > 0) {
                    if (!confirm('You may have file uploads still in process. Are you sure you want to continue')) {
                        _allowSubmit = false;
                    }
                }
                if (status == 'inactive') {
                    $.post('', $('#update-item-form').serializeArray())
                        .done(function () {
                            toastr.success('', 'Draft Saved..', {
                                "positionClass": "toast-bottom-right",
                                "preventDuplicates": true,
                                "onclick": null,
                                "showDuration": "500",
                                "hideDuration": "500",
                                "timeOut": "1000",
                                "showEasing": "linear",
                                "hideEasing": "linear",
                                "showMethod": "fadeIn",
                                "hideMethod": "fadeOut"
                            });
                        });
                } else {
                    if (_allowSubmit) {
                        $('#update-item-form').submit();
                    }
                }
            })
        }
            ;


        /**
         * Init fine uploader instance
         * @see http://fineuploader.com/
         */
        Metis.prototype.createFineUploaderInstanceForItemForm = function () {
            _uploader = new qq.FineUploader({
                element: $('#fine-uploader-item-container')[0],
                template: 'fine-uploader-item-template',
                request: {
                    endpoint: _self.getFileRoute(false),
                    inputName: _slug,
                    customHeaders: {
                        'X-CSRF-TOKEN': window.APP_CSRF_TOKEN
                    }
                },
                callbacks: {
                    onComplete: function (i, b, response) {
                        if (response.success) {
                            setTimeout(function () {
                                $('.qq-file-id-' + String(i)).remove();
                                toastr.success(b, 'Upload Complete')
                            }, 100);
                            _self.updateFileListTable('edit');
                        } else {
                            toastr.error(b, 'Upload Failed')
                        }
                    }
                },
                validation: {
                    sizeLimit: _sizeLimit
                },
                chunking: {
                    enabled: true,
                    concurrent: {
                        enabled: true
                    },
                    success: {
                        endpoint: _self.getFileRoute(false) + '/complete'
                    }
                },
                resume: {
                    enabled: true
                },
                retry: {
                    enableAuto: true,
                    showButton: true
                },
                autoUpload: true,
                debug: false,
                maxConnections: 8

            });

        };

        /**
         *
         */
        Metis.prototype.initCollectionTable = function () {
            jQuery.extend(jQuery.fn.dataTableExt.oSort, {

                "commonAncestorName-asc": function (str1, str2) {
                    if (str1 == "")
                        return 1;
                    if (str2 == "")
                        return -1;
                    str1 = str1.toLowerCase();
                    str2 = str2.toLowerCase();
                    return ((str1 < str2) ? -1 : ((str1 > str2) ? 1 : 0));
                },

                "commonAncestorName-desc": function (str1, str2) {
                    if (str1 == "")
                        return 1;
                    if (str2 == "")
                        return -1;
                    str1 = str1.toLowerCase();
                    str2 = str2.toLowerCase();
                    return ((str1 < str2) ? 1 : ((str1 > str2) ? -1 : 0));
                }
            });

            const collectionTable = $('#collection-table');

            function getActionButtonsColumn(data) {
                return {
                    data: function (data) {
                        return { 'id': data.id, 'status': data.status };
                    },
                    render: function (data) {
                        var id = data.id;
                        var status = data.status;
                        var buttons;
                        if (window.Laravel.user.can['write']) {

                            buttons = '<div class="btn-group" role="group">' +
                                '<button type="button" class="btn btn-primary btn-xs table-item-view" data-id="' + id + '">' +
                                '<i class="glyphicon glyphicon-open"></i> view</button>' +
                                '<button type="button" class="btn btn-primary btn-xs table-item-edit" data-id="' + id + '">' +
                                '<i class="glyphicon glyphicon-edit"></i> edit</button>';
                            if (status != 'deleted') {
                                buttons += '<button type="button" class="btn btn-primary btn-xs table-item-delete" data-id="' + id + '">' +
                                    '<i class="glyphicon glyphicon-remove"></i>  delete</button>';
                            } else {
                                buttons += '<button class="btn btn-primary btn-xs table-item-undelete" data-id="' + id + '">' +
                                    '<i class="fa fa-undo"></i> undelete</button>';
                            }
                            buttons += '</div></td>';
                        } else {
                            buttons = '<div class="btn-group">' +
                                '<button class="btn btn-primary btn-xs table-item-view" data-id="' + id + '">' +
                                '<i class="glyphicon glyphicon-open"></i> view</button>' +
                                '</div></td>';
                        }
                        return buttons;
                    }
                };
            }

            function getStatusColumn(data) {
                return {
                    data: 'status',
                    render: function (status) {
                        return data.statuses[status];
                    }
                };
            }

            function load_data() {
                const button = $('#group-by-button');
                button.text('Loading...').attr('disabled', true);

                $.ajax({
                    url: _area + _slug + '/list'
                })
                    .done(function (data) {
                        collectionTable.DataTable({
                            destroy: true,
                            data: data.data,
                            columnDefs: [
                                {
                                    targets: [7],
                                    orderable: false
                                },
                                {
                                    'render': function (data, type, row) {
                                        let fns = "<ul class='common-ancestor'>";
                                        data.forEach(function (fn) {
                                            fns += ("<li>" + fn + "</li>");
                                        });
                                        fns += "</ul>";
                                        return fns;
                                    },
                                    'targets': [5]
                                }
                            ],
                            columns: [
                                { data: 'id' },
                                { data: 'title' },
                                { data: 'author' },
                                getStatusColumn(data),
                                { data: 'sourceTypeName' },
                                { data: button.data('label') == "Common Ancestor" ? "commonAncestors" : "familyNames" },
                                { data: 'date' },
                                { data: 'updated_at' },
                                getActionButtonsColumn(data)
                            ]
                        });

                        button.text("Group by " + button.data('label')).attr('disabled', false);
                    });
            }

            function load_grouped_data() {
                const button = $('#group-by-button');
                button.text('Loading...').attr('disabled', true);

                const groupListUrl = button.data('label') == "Common Ancestor" ? '/groupedAncestorList' : '/groupedFamilyList'
                $.ajax({
                    url: _area + _slug + groupListUrl
                })
                    .done(function (data) {
                        collectionTable.DataTable({
                            destroy: true,
                            data: data.data,
                            rowGroup: {
                                dataSrc: button.data('label') == 'Common Ancestor' ? 'commonAncestorName' : 'familyName',
                                emptyDataGroup: 'No ' + button.data('label')
                            },
                            columnDefs: [
                                //{ type: 'commonAncestorName', targets: [5] },
                                { orderable: false, targets: [0, 1, 2, 3, 4, 6, 7] }
                            ],
                            order: [5, 'asc'],
                            columns: [
                                { data: 'id' },
                                { data: 'title' },
                                { data: 'author' },
                                getStatusColumn(data),
                                { data: 'sourceTypeName' },
                                {
                                    data: button.data('label') == 'Common Ancestor' ? 'commonAncestorName' : 'familyName',
                                    visible: false
                                },
                                { data: 'date' },
                                { data: 'updated_at' },
                                getActionButtonsColumn(data)
                            ]
                        });

                        button.text('Ungroup Items').attr('disabled', false);
                    });
            }

            load_data();

            $('#group-by-button').on('click', function (event) {
                const button = $(event.target);
                if (button.hasClass('ungrouped')) {
                    load_grouped_data();
                    button.removeClass('ungrouped');
                    button.addClass('grouped');
                } else if (button.hasClass('grouped')) {
                    button.removeClass('grouped');
                    button.addClass('ungrouped');
                    load_data();
                }
            });

            collectionTable.on('click', '.table-item-view', function (e) {
                e.preventDefault();
                var id = $(this).data('id');
                location.href = _area + _slug + '/item/' + id + '/view';
            });

            collectionTable.on('click', '.table-item-edit', function (e) {
                e.preventDefault();
                var id = $(this).data('id');
                location.href = _area + _slug + '/item/' + id + '/edit';
            });

            collectionTable.on('click', '.table-item-delete', function (e) {
                e.preventDefault();
                var id = $(this).data('id');
                $.ajax({
                    url: _area + _slug + '/item/' + id,
                    method: 'post',
                    data: { _method: 'delete', _token: window.APP_CSRF_TOKEN }
                })
                    .done(function () {
                        location.href = _area + _slug;
                    })
            });

            collectionTable.on('click', '.table-item-undelete', function (e) {
                e.preventDefault();
                var id = $(this).data('id');
                $.ajax({
                    url: _area + _slug + '/item/' + id + '/undelete',
                    method: 'post',
                    data: { _token: window.APP_CSRF_TOKEN }
                })
                    .done(function () {
                        location.href = _area + _slug;
                    })
            });
        }

        Metis.prototype.startPartialDateSelector = function (selectorID) {
            $(selectorID).find(".date_select2").select2({ minimumResultsForSearch: 32, placeholder: 0 });
            const yearSelector = $(selectorID).find(".year");
            const monthSelector = $(selectorID).find(".month");
            const daySelector = $(selectorID).find(".day");

            function dateSelectorOnChange() {
                metis.updatePartialDateSelector(yearSelector, monthSelector, daySelector);
            }

            yearSelector.on('change', dateSelectorOnChange);
            monthSelector.on('change', dateSelectorOnChange);

            metis.updatePartialDateSelector(yearSelector, monthSelector, daySelector);
        }

        Metis.prototype.updatePartialDateSelector = function (yearSelector, monthSelector, daySelector) {
            const year = yearSelector.select2('data')[0].id - 1 + 1;
            const month = monthSelector.select2('data')[0].id - 1; // JS date objects count months from 0-11
            const day = daySelector.select2('data')[0].id - 1 + 1;

            const days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // Since year could be unknown, assume leap-year until proven otherwise
            // check for february and leap year
            if (month === 1 && year !== 0 && new Date(year, month, 29).getMonth() === 2) days[1]--; // new date will return march 1st in non-leap years when given february 29th

            daySelector.empty();
            daySelector.append($("<option>").attr("value", 0).text('Unknown'));
            if (month !== -1) { // Month is not unknown
                for (let i = 1; i <= days[month]; i++) {
                    daySelector.append($("<option>").attr("value", i).text(i));
                }
                daySelector.val(day >= days[month] ? days[month] : day);
            } else { // Month is unknown - cannot select a day without a month, so unknown
                daySelector.val(0);
            }
        }

        return new Metis();


    })(window, document, jQuery);

