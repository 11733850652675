/**
 * Instance of BladeHelper
 * @var bladeHelper
 * @type {BladeHelper}
 */
var bladeHelper = (/**
 * @param {Window} window
 * @param {HTMLDocument} document
 * @param {jQuery} $
 */
    function (window, document, $) {
    'use strict';
    var _self,
        _callers = {};

    /**
     *
     * Create a new instance.
     *
     * Note: an instance is created when this is file loaded
     *
     * @class BladeHelper
     * @classdesc Contains basic functions for calling functions using data-call-fn attribute.
     * Used to remove inline js from blade (et.al) but allow passing parameters fluently.
     * @copyright 2016, Memorial University of Newfoundland
     * @author Dale Conway <conway@mun.ca>
     */
    function BladeHelper() {
        _self = this;
    }

    /**
     * Parameters passed to functions
     * @type {Array}
     */
    BladeHelper.prototype.params = [];

    /**
     * Extend functions to be called upon by
     * @type {{}}
     */
    BladeHelper.prototype.registerCaller = function (name, fn) {
        _callers[name] = fn;
    };

    /**
     * Initialize the library looking for each 'data-call-fn' attribute, process any parameters and call the function
     */
    BladeHelper.prototype.init = function () {
        $('span[data-call-fn]').each(function () {
            _self.params = JSON.parse($(this).text());
            var callFunction = $(this).data('call-fn');
            if (typeof _callers[callFunction] === 'function') {
                _callers[callFunction].call(_self);
            }

        })
    };

    /**
     * Get a parameter, these are passed from blade (et. al.) and processed by init.
     * @param param
     * @returns {*}
     */
    BladeHelper.prototype.getParameter = function (param) {
        if (this.params[param] !== 'undefined') {
            return this.params[param];
        }
        return null;
    };


    return new BladeHelper()


})(window, document, jQuery);
